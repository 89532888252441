.bounce {
  animation: bounce 4s ease 1;
}

@keyframes bounce {
  0%,
  20% {
    left: 0px;
  }
  20%,
  30%,
  50%,
  70%,
  80% {
    left: 0px;
  }
  40% {
    left: -30px;
  }
  60% {
    left: -15px;
  }
  80%,
  100% {
    left: 0px;
  }
}
